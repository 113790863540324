function transformBoardToUpdateModel(board) {
  return {
    name: {
      en: board.name.en,
      bg: board.name.bg
    },
    description: {
      en: board.description.en,
      bg: board.description.bg
    },
    image: board.image,
    thumbnailPath: board.thumbnailPath,
    positions: board.positions,
    price: board.price,
    promoPrice: board.promoPrice,
    goldPrice: board.goldPrice,
    new: board.new,
    default: board.default
  };
}

export default {
  transformBoardToUpdateModel
};
