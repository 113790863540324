<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-title>
        <span v-if="this.board.id">
          {{ $t('boards.editBoard') }}
        </span>
        <span v-else>{{ $t('boards.createBoard') }}</span>
        <div class="flex-grow-1"></div>
        <v-btn depressed color="primary" to="/boards">
          <v-icon>mdi-chevron-left</v-icon>
          <span>{{ $t('boards.backToBoards') }}</span>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <manage-board-form
          v-if="!loading"
          :editted-board="board"
        ></manage-board-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ManageBoardForm from '../components/ManageBoardForm';
import httpService from '../services/boards.http.service';

export default {
  name: 'manage-boards-page',
  components: {
    ManageBoardForm
  },
  data() {
    return {
      board: {},
      loading: false
    };
  },
  async created() {
    const id = this.$route.params.id;

    if (id) {
      this.loading = true;

      const { result } = await httpService.get(id);
      this.board = result;

      this.loading = false;
    }
  }
};
</script>
