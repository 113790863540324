export default {
  none: 'none',
  task: 'task',
  fact: 'fact',
  quote: 'quote',
  question: 'question',
  random: 'random',
  bonus: 'bonus',
  specialBonus: 'specialBonus'
};
