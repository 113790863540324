<template>
  <v-form ref="manageBoardForm">
    <v-row>
      <v-col md="12" class="pb-0">
        <h3>{{ $t('common.name') }}</h3>
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
          solo
          v-model="board.name.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
          solo
          v-model="board.name.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="pb-0">
        <h3>{{ $t('common.description') }}</h3>
      </v-col>
      <v-col md="6" sm="12">
        <v-textarea
          solo
          v-model="board.description.en"
          :label="$t('languages.en')"
        ></v-textarea>
      </v-col>
      <v-col md="6" sm="12">
        <v-textarea
          solo
          v-model="board.description.bg"
          :label="$t('languages.bg')"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.image') }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-text-field
          solo
          class="pa-0 my-0"
          v-model="board.image"
          :rules="[rules.required]"
          :label="$t('common.image')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.thumbnailPath') }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-text-field
          solo
          class="pa-0 my-0"
          v-model="board.thumbnailPath"
          :rules="[rules.required]"
          :label="$t('common.thumbnailPath')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="pb-0">
        <h3>{{ $t('boards.positonsCount') }}</h3>
      </v-col>
      <v-col md="6" sm="8">
        <v-text-field
          solo
          v-model="positionsCount"
          type="number"
          :rules="[rules.required]"
          :label="$t('boards.positonsCount')"
          min="0"
          max="24"
        ></v-text-field>
      </v-col>
      <v-col md="3" sm="4">
        <v-btn large type="button" color="primary" @click="addPositions()">
          {{ $t('boards.addPositions') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="3"
        xs="6"
        v-for="(position, index) of board.positions"
        :key="index"
      >
        <strong>{{ index + 1 }}</strong>
        <v-select
          v-model="position.type"
          :items="positionTypes"
          solo
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="py-0">
        <h3>{{ $t('common.price') }}</h3>
        <v-text-field
          class="mt-2"
          solo
          v-model="board.price"
          type="number"
          :label="$t('common.price')"
        ></v-text-field>
      </v-col>
      <v-col md="6" class="py-0">
        <h3>{{ $t('common.promoPrice') }}</h3>
        <v-text-field
          class="mt-2"
          solo
          v-model="board.promoPrice"
          type="number"
          :label="$t('common.promoPrice')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.goldPrice') }}</h3>
      </v-col>
      <v-col md="6" sm="12" class="pb-0">
        <v-text-field
          solo
          v-model="board.goldPrice"
          type="number"
          :label="$t('common.goldPrice')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <v-checkbox
          class="mt-0"
          v-model="board.new"
          :label="$t('common.new')"
          color="primary"
        ></v-checkbox>
      </v-col>
      <v-col md="3">
        <v-checkbox
          class="mt-0"
          v-model="board.default"
          :label="$t('common.default')"
          color="primary"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="mr-4" @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import validators from '../../../core/validators';
import 'codemirror/lib/codemirror.css';
import boardPositionTypes from '../../../enums/board.position.types';
import httpService from '../services/boards.http.service';
import mapper from '../mappers/boards.mapper';

export default {
  name: 'manage-board-form',
  props: {
    edittedBoard: {
      type: Object
    }
  },
  data() {
    return {
      board: {
        name: {
          en: '',
          bg: ''
        },
        description: {
          en: '',
          bg: ''
        },
        image: '',
        thumbnailPath: '',
        positions: [],
        price: 0,
        promoPrice: '',
        goldPrice: 0,
        default: false,
        new: true
      },
      positionsCount: '',
      positionTypes: Object.values(boardPositionTypes).map(type => ({
        text: this.$i18n.t(`boards.positionTypes.${type}`),
        value: type
      })),
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    if (this.edittedBoard.id) {
      this.board = {
        ...this.edittedBoard
      };
      this.positionsCount = this.edittedBoard.positions.length;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageBoardForm.validate()) {
        return;
      }

      if (this.board.id) {
        await httpService.update(
          this.board.id,
          mapper.transformBoardToUpdateModel(this.board)
        );

        this.$notify({
          type: 'success',
          text: this.$i18n.t('boards.updateSuccess')
        });
      } else {
        await httpService.create(this.board);

        this.$notify({
          type: 'success',
          text: this.$i18n.t('boards.createSuccess')
        });
      }

      this.$router.push({ name: 'boards' });
    },
    addPositions() {
      this.board.positions = [];

      for (let i = 0; i < this.positionsCount; i++) {
        this.board.positions.push({
          type: boardPositionTypes.none
        });
      }
    }
  }
};
</script>
